<template>
    <div>
        <Breadcrumbs main="" title="Maintenance Window" />
        <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div slot="with-padding">
              <b-row class="mt-3">
                <b-col md="3" class="input-group">
                  <div class="form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class=""
                        v-model="filter"
                        placeholder="Type to Search"
                      ></b-form-input>
                    </b-input-group>
									</div>
                </b-col>
                <b-col class="mt-2 mt-md-0 text-md-left text-center">
                  <b-button size="lg" variant="primary" class="btn" @click="add_maint_modal = true" >Add New Maintenance +</b-button>
                </b-col>
                <b-col class="col-md-6 col-12 mt-2 mt-md-0">
                  <b-row>
                    <b-col class="col-md-12 text-right">
                      Per Page
                      <b-form-select
                      class="col-md-2 col-6"
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue">
                <b-table
                 class="text-left"
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :current-page="currentPage"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #cell(name)="data">
                  <span class="f-w-900">{{ data.item.name }}</span>
                </template>
                <template #cell(duration)="data">
                  <span>{{ data.item.duration }} min.</span>
                </template>
                <template #cell(startTime)="data">
                  <!-- <span v-if="data.item.type == 1"> 
                    - -->
                    <!-- <span v-for="l in data.item.value" :key="l">
                      {{ new Date(l).toLocaleTimeString([], { hour: "2-digit",minute: "2-digit",hour12: true }) }}
                    </span> -->
                  <!-- </span> -->
                  <span>
                    {{ data.item.startTime }}
                      <!-- {{ new Date(data.item.startTime).toLocaleTimeString([], { hour: "2-digit",minute: "2-digit",hour12: false }) }} -->
                  </span>
                </template>
                <template #cell(type)="data">
                  <span>{{ maintenance_opt[data.item.type].text }}</span>
                </template>
                <template #cell(status)="data">
                    <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.status @change="statusUpdate(data.item)"><span class="switch-state" :class="data.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(value)="data">
                  <span v-if="data.item.type == 3">
                    <span v-for="(t,i) in data.item.value" :key="i">
                      {{ weekly_opt[t]}}{{ i < data.item.value.length - 1 ? ', ' : '' }}
                    </span>
                  </span>
                  <span v-else-if="data.item.type == 4">
                    <span v-for="(j, index) in data.item.value" :key="index">
                      {{ monthly_opt[j] }}{{ index < data.item.value.length - 1 ? ', ' : '' }}
                    </span>
                  </span>
                  <span v-else-if="data.item.type == 1">
                      <span v-for="l in data.item.value" :key="l">
                        {{ l.split('T')[0] }}
                      </span>
                  </span>
                  <span v-else>
                    - 
                  </span>
                </template>
                <template #cell(action)="data">
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deleteMaintenance(data.item._id)"></feather>
                  <feather class="text-info ml-2" type="edit" title="Edit" @click="editMaintenance(data.item)"></feather>         
                </template>
                </b-table>
              </div>
              <b-col md="6" class="mt-3 p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-addmaintenance" size="lg" @hidden="closeModal()" title="Maintenance Window" class="theme-modal" v-model="add_maint_modal" no-close-on-backdrop hide-footer>
      <add-maintenance :add_maint_modal="add_maint_modal" @maintmodal="closeModal" :edit_maint="edit_maint" :maint_data="maint_data"></add-maintenance>
    </b-modal>
 </div>
</template>


<script>
import MaintenanceController from "../../../services/maintenanceController";
import AddMaintenance from "./AddMaintenance.vue";
import moment from 'moment';
export default {
    name: 'Manitenace',
    components:{AddMaintenance},
    data(){
        return{
            maint_data:{},
            add_maint_modal:false,
            edit_maint: false,
            items:[],
            totalRows:0,
            filter:null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 50],
            tablefields: [
                          { key: "name", label: "Name", sortable: true },
                          { key: "status", label: "Status", sortable: false },
                          { key: "type", label: "Type", sortable: false },
                          { key: "value", label: "Value", sortable: false },
                          { key: "startTime", label: "Start Time", sortable: false },
                          { key: "duration", label: "Duration", sortable: false },
                          { key: "timezone", label: "Timezone", sortable: false },
                          { key: "action", label: "Action", sortable: false },
                        ],
            loading: false,
            maintenance_opt:[
              { value: null, text:'Please Select' },
              { value: 1, text:'Once' },
              { value: 2, text:'Daily' },
              { value: 3, text:'Weekly' },
              { value: 4, text:'Monthly' },
            ],
            weekly_opt:{1:'Monday',2:'Tuesday',3:'Wednesday',4:'Thursday',5:'Friday',6:'Saturday',0:'Sunday'},
            monthly_opt:{1:'1st',2:'2nd',3:'3rd',4:'4th',5:'5th',6:'6th',7:'7th',8:'8th',9:'9th',10:'10th',11:'11th',12:'12th',13:'13th',14:'14th',15:'15th',16:'16th',17:'17th',18:'18th',19:'19th',20:'20th',21:'21st',22:'22nd',23:'23rd',24:'24th',25:'25th',26:'26th',27:'27th',28:'28th',29:'29th',30:'30th',31:'31st'},
            userinfo:''
        }
    },
    mounted(){
      this.getMantenance()
    },
    methods:{
        async getMantenance(){
          this.loading = true;
            this.items = []
            let response = await MaintenanceController.getAll()
            if (response.result) {
                this.items = response.data;
                this.totalRows = this.items.length;
            }
            this.loading = false;
        },
        async deleteMaintenance(id){
          if(confirm("Are you sure want to delete?")){
          this.loading = true;
          let response = await MaintenanceController.deleteMaintenance({"mwid": id});
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.getMantenance()
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getMantenance()
          }
        }
        },
        editMaintenance(data){
          this.add_maint_modal = false
          this.add_maint_modal = true
          this.maint_data = data;
          this.edit_maint = true;
        },
        closeModal(){
          this.add_maint_modal = false
          this.edit_maint = false;
          this.getMantenance();
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async statusUpdate(data){
        // this.loading = true;
        var s = true
        if(data.status == true){
            s = false
        }
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        var payload = {"mwid": data._id, "changePayload": {"status":s}}
        let response = await MaintenanceController.editMaintenance(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            // this.getMantenance()
        }
        else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getMantenance()
          }
      }
    }
}

</script>