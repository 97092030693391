import api from './api'
export default {
    addMaintenance(data){
        return api.execute(`POST`,`/mwindow`,data)
    },
    getAll(){
        return api.execute(`GET`,`/mwindow`)
    },
    editMaintenance(data){
        return api.execute(`PUT`,`/mwindow`,data)
    },
    deleteMaintenance(data){
        return api.execute(`DELETE`,`/mwindow`,data)
    }
}